@font-face {
font-family: '__objectivityMediumSlanted_423c20';
src: url(/_next/static/media/b0f4181a10ced076-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityMediumSlanted_Fallback_423c20';src: local("Arial");ascent-override: 62.79%;descent-override: 19.60%;line-gap-override: 4.94%;size-adjust: 117.37%
}.__className_423c20 {font-family: '__objectivityMediumSlanted_423c20', '__objectivityMediumSlanted_Fallback_423c20'
}.__variable_423c20 {--font-objectivity-medium-slanted: '__objectivityMediumSlanted_423c20', '__objectivityMediumSlanted_Fallback_423c20'
}

@font-face {
font-family: '__objectivityRegular_325882';
src: url(/_next/static/media/5709c7324cf3f584-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityRegular_Fallback_325882';src: local("Arial");ascent-override: 63.64%;descent-override: 19.94%;line-gap-override: 5.03%;size-adjust: 115.35%
}.__className_325882 {font-family: '__objectivityRegular_325882', '__objectivityRegular_Fallback_325882'
}.__variable_325882 {--font-objectivity-regular: '__objectivityRegular_325882', '__objectivityRegular_Fallback_325882'
}

@font-face {
font-family: '__objectivityRegularSlanted_9e43cb';
src: url(/_next/static/media/9c9968ca28da2540-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityRegularSlanted_Fallback_9e43cb';src: local("Arial");ascent-override: 63.64%;descent-override: 19.94%;line-gap-override: 5.03%;size-adjust: 115.35%
}.__className_9e43cb {font-family: '__objectivityRegularSlanted_9e43cb', '__objectivityRegularSlanted_Fallback_9e43cb'
}.__variable_9e43cb {--font-objectivity-regular-slanted: '__objectivityRegularSlanted_9e43cb', '__objectivityRegularSlanted_Fallback_9e43cb'
}

@font-face {
font-family: '__objectivitySuper_040fa1';
src: url(/_next/static/media/6fd396410f296df5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivitySuper_Fallback_040fa1';src: local("Arial");ascent-override: 59.73%;descent-override: 18.27%;line-gap-override: 4.61%;size-adjust: 125.91%
}.__className_040fa1 {font-family: '__objectivitySuper_040fa1', '__objectivitySuper_Fallback_040fa1'
}.__variable_040fa1 {--font-objectivity-super: '__objectivitySuper_040fa1', '__objectivitySuper_Fallback_040fa1'
}

@font-face {
font-family: '__objectivitySuperSlanted_cee017';
src: url(/_next/static/media/bd06cf87d92f1900-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivitySuperSlanted_Fallback_cee017';src: local("Arial");ascent-override: 59.73%;descent-override: 18.27%;line-gap-override: 4.61%;size-adjust: 125.91%
}.__className_cee017 {font-family: '__objectivitySuperSlanted_cee017', '__objectivitySuperSlanted_Fallback_cee017'
}.__variable_cee017 {--font-objectivity-super-slanted: '__objectivitySuperSlanted_cee017', '__objectivitySuperSlanted_Fallback_cee017'
}

@font-face {
font-family: '__objectivityThin_72941b';
src: url(/_next/static/media/2903f3d9af1520f0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityThin_Fallback_72941b';src: local("Arial");ascent-override: 65.13%;descent-override: 20.52%;line-gap-override: 5.17%;size-adjust: 112.08%
}.__className_72941b {font-family: '__objectivityThin_72941b', '__objectivityThin_Fallback_72941b'
}.__variable_72941b {--font-objectivity-thin: '__objectivityThin_72941b', '__objectivityThin_Fallback_72941b'
}

@font-face {
font-family: '__objectivityThinSlanted_e79f98';
src: url(/_next/static/media/8fb9da8c3ff0cfe4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityThinSlanted_Fallback_e79f98';src: local("Arial");ascent-override: 65.13%;descent-override: 20.52%;line-gap-override: 5.17%;size-adjust: 112.08%
}.__className_e79f98 {font-family: '__objectivityThinSlanted_e79f98', '__objectivityThinSlanted_Fallback_e79f98'
}.__variable_e79f98 {--font-objectivity-thin-slanted: '__objectivityThinSlanted_e79f98', '__objectivityThinSlanted_Fallback_e79f98'
}

